import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import React from "react";
import { StyledMainContainer } from "./404";

type Props = {
  location: any;
};
// The below is just meant to be a BIG single kanji character which roughly stands for "Create" the opposite of "Destroy"
const CreatePage = ({ location }: Props): JSX.Element => {
  return (
    <Layout hideComponents location={location}>
      <Helmet title="Create" />

      <StyledMainContainer>造</StyledMainContainer>
    </Layout>
  );
};

export default CreatePage;
